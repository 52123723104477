import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Fancybox from "../../component/fancybox.js";
import { CustompagesApi } from "store/slices/custompagesSlice";
import { SettingsApi } from "store/slices/settingSlice";
import { Logout } from "store/slices/authSlice";
import moment from "moment-timezone";
import { ActivePlanApi, FeatureAccessApi } from "store/slices/salonSlice";
import { featureaccess } from "helpers/Functions";
import NoFeatureAccess from "pages/NoFeatureAccess";
import config from "../../config";
import Package from "../../pages/packages"
import SpinLoader from "component/SpinLoader";
import Footer from "./Footer";
// import { Helmet } from "react-helmet-async";
// import config from "../../config";
// ==============================|| MAIN LAYOUT ||============================== //
const MainLayout = () => {
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!currentUser || auth.token == undefined || currentUser === null) {
    dispatch(Logout());
  }
  const location = useLocation();
  const [apiCall, setApiCall] = useState(true);
  const isFeatureAccess = useSelector((state) => state.salon.isFeatureAccess);
  const staffTabview = useSelector((state) => state.staff.isTabView);
  const marketingTabview = useSelector((state) => state.marketing.isTabView);
  const MarketingSelectedView = useSelector((state) => state.marketing.isSelectedView);
  const settingtabview = useSelector((state) => state.setting.isTabView);
  const MarketingSelectedViewArray = ["Pre", "Post"];
  const staffTabviewArray = ["staff", "roster", "price_tier", "staff_access"];
  const settingsTabviewArray = ["ConsultationForms"];
  const isActivePlan = useSelector((state) => state.salon.isActivePlan);
  const ActivePlanApiStatus = useSelector((state) => state.salon.isApiStatus);
  const is_trial_plan = currentUser.salon && currentUser.salon.is_trial_plan;
  const invoice_payment_failed_at = isActivePlan && isActivePlan.invoice_payment_failed_at ? isActivePlan.invoice_payment_failed_at : "";
  const locationFeatureAccess = [
    // { location_path: "/dashboard", access: featureaccess({ name: "Dashboard", featureaccess: isFeatureAccess }) },
    { location_path: "/appointment-campaign", access: featureaccess({ name: "Automated Pre Appointment Campaigns", featureaccess: isFeatureAccess, tab: MarketingSelectedView && MarketingSelectedView.type }) },
    { location_path: "/appointment-campaign", access: featureaccess({ name: "Automated Post Appointment Campaigns", featureaccess: isFeatureAccess, tab: MarketingSelectedView && MarketingSelectedView.type }) },
    // { location_path: "/staff", access: featureaccess({ name: "Staff Management", featureaccess: isFeatureAccess, tab: staffTabview }) },
    // { location_path: "/staff", access: featureaccess({ name: "Staff Rosters", featureaccess: isFeatureAccess, tab: staffTabview }) },
    // { location_path: "/staff", access: featureaccess({ name: "Staff user roles", featureaccess: isFeatureAccess, tab: staffTabview }) },
    // { location_path: "/staff", access: featureaccess({ name: "Tiered Pricing", featureaccess: isFeatureAccess, tab: staffTabview }) },
    // { location_path: "/subscriptions", access: featureaccess({ name: "Subscriptions", featureaccess: isFeatureAccess }) },
    { location_path: "/packages", access: featureaccess({ name: "Packages", featureaccess: isFeatureAccess }) },
    // { location_path: "/settings", access: settingtabview ? featureaccess({ name: "Custom Consultation Forms", featureaccess: isFeatureAccess }) : true, tab: settingtabview },
  ];
  let accessPlan = true;
  locationFeatureAccess.length > 0 &&
    locationFeatureAccess
      .filter((path) => (path.tab ? (settingsTabviewArray.includes(path.tab) && path.location_path == location.pathname) || (MarketingSelectedViewArray.includes(path.tab) && path.location_path == location.pathname) || (staffTabviewArray.includes(path.tab) && path.location_path == location.pathname) : path.location_path == location.pathname))
      // .filter((path) => {
      //   if((path.tab &&(settingsTabviewArray.includes(path.tab) && path.location_path == location.pathname) || (MarketingSelectedViewArray.includes(path.tab) && path.location_path == location.pathname) || (staffTabviewArray.includes(path.tab) && path.location_path == location.pathname))){
      //     return path;
      //   }else if(path.location_path == location.pathname){
      //     return path;
      //   }
      // })
      .map((item) => {
        let tab = item.tab;
        let itemaccessPlan = item.access;
        // if (tab) {
        //   itemaccessPlan = item.access;
        // } else {
        //   itemaccessPlan = item.access;
        // }
        accessPlan = itemaccessPlan;
      });

  useEffect(() => {
    if (!["/calendar"].includes(location && location.pathname)) {
      dispatch(CustompagesApi());
      dispatch(SettingsApi());
    }
  }, [location]);

  useEffect(() => {
    // console.log("conditional: api called")
    //   dispatch(FeatureAccessApi());
    //   dispatch(CustompagesApi());
    //   dispatch(SettingsApi());
    // if(["/settings","/plans"].includes(location && location.pathname)){
    //   console.log("conditional: api called")
    //   dispatch(FeatureAccessApi());
    //   dispatch(CustompagesApi());
    //   dispatch(SettingsApi());
    // }
    dispatch(FeatureAccessApi());
    dispatch(ActivePlanApi()).then(action => {
      if (action.meta.requestStatus === "fulfilled") {
        setApiCall(false)
      } else if (action.meta.requestStatus === "rejected") {
        setApiCall(false)
      }
    });
    moment.tz.setDefault(currentUser.salon && currentUser.salon.timezone ? currentUser.salon.timezone : "UTC");
    // const timezoneNames = ['short', 'long', 'shortOffset', 'longOffset', 'shortGeneric', 'longGeneric']

    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: currentUser.salon && currentUser.salon.timezone ? currentUser.salon.timezone : "UTC",
      timeZoneName: "long",
    }).format(new Date());

    const date = new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738));
    // moment.tz.setDefault("UTC");
  }, []);
  // if ((location && location.pathname === "/dashboard") || (location && location.pathname === "/calendar") || (location && location.pathname === "/settings")) {
  //   dispatch(GetUser());
  // }

  return (
    <>
      {/* <Helmet>
        <link rel="stylesheet" href={config.baseUrl + "/css/style.css"} />
      </Helmet> */}
      <Fancybox />
      <main>
        <div className="body-wrapper">
          <Header />
          <Sidebar />
          {/* <Outlet /> */}
          {isActivePlan ? <> {accessPlan ? <Outlet /> : <NoFeatureAccess />}</> : (
            <>
              {(ActivePlanApiStatus && ActivePlanApiStatus.ActivePlanApi === "loading") || apiCall ? (
                <div className="loader-spinner-center">
                  <SpinLoader />
                </div>
              ) : (
                <Package />
              )}
            </>
          )
          }
          {is_trial_plan || invoice_payment_failed_at ? <Footer /> : null}
        </div>
      </main>
    </>
  );
};

export default MainLayout;
