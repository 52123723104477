import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import config from "../../../../config"
import { TaxValueApi } from "store/slices/taxSlice";
import { ActiveBtnState, ClientMemberShipTotalPay, CompletedSaleDataForRefund, OpenClientInvoiceDetails, OpenStripeRefundCompletedSaleModal, RefundStripeToken, TaxAmountClientPaidInvoice } from "store/slices/clientinvoiceSlice";
import { useTranslation } from "react-i18next";

const ClientPaidInvoice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [TaxPercentage, setTaxPercentage] = useState("");
  const [TotalAudCosts, setTotalAudCosts] = useState(0);
  const [CalculatedTaxAmounts, setCalculatedTaxAmounts] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const rightDrawerOpened = useSelector((state) => state.clientinvoice.isOpenClientInvoiceDetailsTab);
  // const isSaleCompletedData = useSelector((state) => state.clientinvoice.isClientInvoiceDetailsTabData);
  const isSaleCompletedData = useSelector((state) => state.clientinvoice.isClientInvoiceDetail);

  const isTaxAmountClientPaidInvoice = useSelector((state) => state.clientinvoice.isTaxAmountClientPaidInvoice);
  const isClientMemberShipTotalPay = useSelector((state) => state.clientinvoice.isClientMemberShipTotalPay);
  const totalServiceCosts = useSelector((state) => state.clientinvoice.totalServiceCosts);
  let TaxInvoiceNumber = isSaleCompletedData && isSaleCompletedData.invoice_number;
  let InvoiceDate = isSaleCompletedData && isSaleCompletedData.invoicedate;
  const formattedInvoiceDate = InvoiceDate ? moment(InvoiceDate, "YYYY-MM-DD").format('Do MMMM YYYY') : "";
  const ClientData = isSaleCompletedData && isSaleCompletedData.client;
  let client_first_name = isSaleCompletedData && isSaleCompletedData.client && ClientData.first_name;
  let client_last_name = isSaleCompletedData && isSaleCompletedData.client && ClientData.last_name;
  const clientFullName = client_first_name ? client_first_name + " " + client_last_name : "";
  const CartData = isSaleCompletedData && isSaleCompletedData.cart;
  const InvoiceTax = useSelector((state) => state.tax.isTaxValue);
  let taxPercentage = InvoiceTax && InvoiceTax.percentage;
  let total_pay = isSaleCompletedData && isSaleCompletedData.total_pay;
  // let tax = taxPercentage && total_pay ? total_pay / taxPercentage : "";
  // let memberShipTax = taxPercentage && isClientMemberShipTotalPay ? isClientMemberShipTotalPay / taxPercentage : "";
  const salePaid = isSaleCompletedData && isSaleCompletedData.payment.length > 0 && isSaleCompletedData.payment.filter((payment) => payment.type === "Sale").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositPaid = isSaleCompletedData && isSaleCompletedData.payment.length > 0 && isSaleCompletedData.payment.filter((payment) => payment.type === "Deposit").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositRefund = isSaleCompletedData && isSaleCompletedData.payment.length > 0 && isSaleCompletedData.payment.filter((payment) => payment.type === "DepositRefund").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositBalance = salePaid + depositPaid - depositRefund != null && salePaid + depositPaid - depositRefund !== "" && salePaid + depositPaid - depositRefund > 0 ? salePaid + depositPaid - depositRefund : 0;
  const addedserviceInCart = CartData && CartData.length > 0 ? CartData.filter((item) => item.type === "Service") : [];
  const singlePaymentSale = isSaleCompletedData && isSaleCompletedData.payment && isSaleCompletedData.payment.length > 0 ? isSaleCompletedData.payment.filter((item) => item.type === "Sale") : [];
  const singlepaymentCost = singlePaymentSale && singlePaymentSale.length > 0 ? singlePaymentSale.reduce((sum, item) => sum + parseFloat(item.amount), 0) : 0;
  const singlePaymentSaleRefund = isSaleCompletedData && isSaleCompletedData.payment && isSaleCompletedData.payment.length > 0 ? isSaleCompletedData.payment.filter((item) => item.type === "DepositRefund") : [];
  const singlepaymentCostRefund = singlePaymentSaleRefund && singlePaymentSaleRefund.length > 0 ? singlePaymentSaleRefund.reduce((sum, item) => sum + parseFloat(item.amount), 0) : 0;
  const appliedvoucherto = isSaleCompletedData && isSaleCompletedData.appliedvoucherto ? isSaleCompletedData.appliedvoucherto : "";
  const saleStatus = isSaleCompletedData?.status ?? '';
  // const BalanceViews = (totalServiceCosts || 0) - (salePaid || 0) - (depositPaid || 0);
  // const depositBalance = depositPaid >= depositRefund ? depositPaid + depositRefund : 0;

  useEffect(() => {
    dispatch(TaxValueApi());
  }, []);

  useEffect(() => {
    let totalCost = 0;
    let calculatedTax = 0;

    if (CartData && CartData.length > 0) {
      CartData.forEach(item => {
        let cost = 0;

        if (item && item?.type) {
          switch (item.type) {
            case "Membership":
            case "Product":
            case "Subscription":
            case "Service":
              cost = item.cost ? parseFloat(item.cost) : 0;
              break;
            case "OneOffVoucher":
            case "Voucher":
              cost = item.voucherto && item.voucherto.amount ? parseFloat(item.voucherto.amount) : 0;
              break;
            case "Appointment":
            case "AddOnServices":
            case "AddAnotherServices":
              cost = item.cost ? parseFloat(item.cost) : 0;
              break;
            default:
              cost = 0;
          }
          totalCost += cost;
        }
      });

      calculatedTax = taxPercentage && totalCost ? totalCost / taxPercentage : 0;
    }

    setTotalAudCosts(totalCost);
    setCalculatedTaxAmounts(calculatedTax);
  }, [CartData, taxPercentage,InvoiceTax]);



  return (
    <div className={"drawer client-invoice-drawer " + rightDrawerOpened}>
      <div className="drawer-wrp position-relative">
        <div className="drawer-header">
          <h2 className="mb-4 pe-md-5 pe-3">Invoice</h2>
          <a href="#" className="close" onClick={() => {
            dispatch(OpenClientInvoiceDetails(""))
          }}>
            <img src={config.imagepath + "close-icon.svg"} alt="" />
          </a>
        </div>
        <hr />
        <div className="drawer-body d-flex flex-wrap">
          {openModal && (
            <>
              <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body pb-0">
                      <div className="p-3">
                        <h5 className="mb-3 fw-semibold text-dark">{t("Refund Warning")}</h5>
                        <p className="mb-3 fw-semibold">{t("Full Amount is Already Refunded For This Appointments")}</p>
                      </div>
                      <div className="row mt-3 bg-cream p-4 justify-content-center">
                        <div className="col-6">
                          <button className="btn btn-primary w-100" type="button" onClick={() => {
                            setOpenModal(false);
                          }}>
                            {t("OK")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openModal && <div className="modal-backdrop show"></div>}
            </>
          )}
          <ul className="sale-comlete-data list-unstyled">
            <li className="">
              <h5 className="mb-1 fw-semibold">Tax Invoice {TaxInvoiceNumber ? TaxInvoiceNumber : "#0001"}</h5>
              <p className="mb-0">{formattedInvoiceDate}</p>
            </li>
            <li className="border-top my-3 pt-3">
              <label>
                Invoice to:
                <label>
                  <h6 className="mb-0">{clientFullName}</h6>
                </label>
              </label>
            </li>
            {CartData && CartData.length > 0 ? (
              Object.keys(CartData).map((item, i) => {
                let ServiceName = CartData[item] && CartData[item].services && CartData[item].services.name;
                let StaffData = CartData[item] && CartData[item].staff;
                let StaffFirstName = CartData[item] && StaffData && StaffData.first_name;
                let StaffLastName = CartData[item] && StaffData && StaffData.last_name;
                let ServiceCosts = CartData[item] && CartData[item].cost;
                let staffFullName = CartData[item] && StaffData ? StaffFirstName + " " + StaffLastName : "";
                let AppointMentObjData = CartData[item] && CartData[item].appointment;
                let serviceStartTimes = CartData[item] && AppointMentObjData && AppointMentObjData.start_time ? AppointMentObjData.start_time : "";
                let serviceStart = moment(serviceStartTimes, "HH:mm:ss").format("hh:mm A");
                let serviceEndsTimes = CartData[item] && AppointMentObjData && AppointMentObjData.end_time ? AppointMentObjData.end_time : "";
                let serviceEnds = moment(serviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                // let AddOnServiceData = CartData[item] && CartData[item].appointment && CartData[item].appointment.addonservices.filter(() => CartData[item] && CartData[item].type && CartData[item].type == "Appointment");
                let AddOnServiceData = CartData[item] && CartData[item].addonaddanotherservices && CartData[item].addonaddanotherservices.length > 0 ? CartData[item].addonaddanotherservices : [];

                let serviceDate = CartData[item] && CartData[item].created_at ? moment(CartData[item].created_at, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").format("DD-MM-YYYY") : "";

                let membershipCosts = CartData[item] && CartData[item].type && CartData[item].type === "Membership" && CartData[item].cost ? CartData[item].cost : 0;
                let oneoffvoucherCosts = CartData[item] && CartData[item].type && CartData[item].type === "OneOffVoucher" && CartData[item].voucherto && CartData[item].voucherto.amount ? CartData[item].voucherto.amount : 0;
                let voucherCosts = CartData[item] && CartData[item].type && CartData[item].type === "Voucher" && CartData[item].voucherto && CartData[item].voucherto.amount ? CartData[item].voucherto.amount : 0;
                let SubscriptionCosts = CartData[item] && CartData[item].type && CartData[item].type === "Subscription" && CartData[item].cost ? CartData[item].cost : 0;
               
                let productCost = CartData[item] && CartData[item].type && CartData[item].type === "Product" && CartData[item].cost ? CartData[item].cost : 0;
                let addedserviceCosts = CartData[item] && CartData[item].type && CartData[item].type === "Service" && CartData[item].cost ? CartData[item].cost : 0;

                // else if (AppointMentObjData) {
                //   TotalAudCosts += parseFloat(AppointMentObjData.cost)
                //   let addanotherservicesCost = 0;
                //   if (AppointMentObjData.addanotherservices && AppointMentObjData.addanotherservices.length > 0) {
                //     AppointMentObjData.addanotherservices.map((item) => {
                //       TotalAudCosts += parseFloat(item.cost ?? 0);
                //     });
                //   }
                //   let addonservicesCost = 0;
                //   if (AppointMentObjData.addonservices && AppointMentObjData.addonservices.length > 0) {
                //     AppointMentObjData.addonservices.map((item) => {
                //       TotalAudCosts += parseFloat(item.cost ?? 0);
                //     });
                //   }
                // }
                
                let voucherName = CartData[item] && CartData[item].type && CartData[item].type === "Voucher" && CartData[item].voucherto && CartData[item].voucherto.first_name && CartData[item].voucherto.last_name ? CartData[item].voucherto.first_name + " " + CartData[item].voucherto.last_name : "";
                let SubscriptionName = CartData[item] && CartData[item].type && CartData[item].type === "Subscription" && CartData[item].subscription && CartData[item].subscription.name ? CartData[item].subscription.name : "";
                let oneoffvoucherName = CartData[item] && CartData[item].type && CartData[item].type === "OneOffVoucher" && CartData[item].voucherto && CartData[item].voucherto.first_name && CartData[item].voucherto.last_name ? CartData[item].voucherto.first_name + " " + CartData[item].voucherto.last_name : "";
                let membershipName = CartData[item] && CartData[item].type && CartData[item].type === "Membership" && CartData[item].membership && CartData[item].membership.name ? CartData[item].membership.name : "";
                let productName = CartData[item] && CartData[item].type && CartData[item].type === "Product" && CartData[item].products && CartData[item].products.name ? CartData[item].products.name : "";
                let product_staff_name = CartData[item] && CartData[item].type && CartData[item].type === "Product" && CartData[item].staff && (CartData[item].staff.first_name && CartData[item].staff.last_name) ? CartData[item].staff.first_name + " " + CartData[item].staff.last_name : "";
                let dateOfInvoiced = CartData[item] && CartData[item].created_at ? moment(CartData[item].created_at, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").format("DD/MM/YYYY") : "";
                let clientsubscriptionplan = CartData[item] && CartData[item].type && CartData[item].type === "Subscription" && CartData[item]?.clientsubscriptionplan && CartData[item]?.clientsubscriptionplan.length > 0 ? CartData[item]?.clientsubscriptionplan[0] : "";
                let commencement_date = clientsubscriptionplan && clientsubscriptionplan?.commencement_date ? moment(clientsubscriptionplan?.commencement_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "";
                let custom_first_amount = CartData[item] && CartData[item].type && CartData[item].type === "Subscription" && clientsubscriptionplan?.one_off_payments && clientsubscriptionplan?.one_off_payments == 1 && clientsubscriptionplan?.one_off_amount ? clientsubscriptionplan?.one_off_amount : "";
                let client_subscription_signature = CartData[item] && CartData[item].type && CartData[item].type === "Subscription" && CartData[item].storage_url && clientsubscriptionplan?.client_signature ? `${CartData[item].storage_url}/${clientsubscriptionplan?.client_signature}` : "";
                let client_subscription_staff = CartData[item] && CartData[item].type && CartData[item].type === "Subscription" && CartData[item].staff && (CartData[item].staff.first_name && CartData[item].staff.last_name) ? `${CartData[item].staff.first_name} ${CartData[item].staff.last_name}` : "";

                return (
                  <React.Fragment key={i}>
                    {CartData[item] && CartData[item].type && (CartData[item].type === "Appointment" || CartData[item].type === "AddOnServices" || CartData[item].type === "AddAnotherServices") ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <label htmlFor="">{ServiceName ? ServiceName : ""}</label>
                              <h6 className="mb-1">With {staffFullName} On {serviceDate}</h6>
                              <span>Quantity: 1</span>
                            </div>
                            <label className="col-2 text-end">${ServiceCosts ? ServiceCosts : "0"}</label>
                          </div>
                        </li>
                      </>
                    ) : ""}
                    {CartData[item] && CartData[item].type && CartData[item].type === "Service" ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <label htmlFor="">{ServiceName ? ServiceName : ""}</label>
                              <h6 className="mb-1">With {staffFullName} On {serviceDate}</h6>
                              <span>Quantity: 1</span>
                            </div>
                            <label className="col-2 text-end">${ServiceCosts ? ServiceCosts : "0"}</label>
                          </div>
                        </li>
                      </>
                    ) : ""}
                    {CartData[item] && CartData[item].type && CartData[item].type === "Membership" ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <label htmlFor="">{membershipName ? membershipName : ""}</label>
                              <h6 className="mb-1">{dateOfInvoiced}</h6>
                              <span>Quantity: 1</span>
                            </div>
                            <label className="col-2 text-end">${membershipCosts ? membershipCosts : "0"}</label>
                          </div>
                        </li>
                      </>
                    ) : ""}
                    {CartData[item] && CartData[item].type && CartData[item].type === "Voucher" ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <label htmlFor="">{voucherName ? voucherName : ""}</label>
                              <h6 className="mb-1">{dateOfInvoiced}</h6>
                              <span>Quantity: 1</span>
                            </div>
                            <label className="col-2 text-end">${voucherCosts ? voucherCosts : "0"}</label>
                          </div>
                        </li>
                      </>
                    ) : ""}
                    {CartData[item] && CartData[item].type && CartData[item].type === "OneOffVoucher" ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <label htmlFor="">{oneoffvoucherName ? oneoffvoucherName : ""}</label>
                              <h6 className="mb-1">{dateOfInvoiced}</h6>
                              <span>Quantity: 1</span>
                            </div>
                            <label className="col-2 text-end">${oneoffvoucherCosts ? oneoffvoucherCosts : "0"}</label>
                          </div>
                        </li>
                      </>
                    ) : ""}
                    {CartData[item] && CartData[item].type && CartData[item].type === "Product" ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <label htmlFor="">{productName ? productName : ""}</label>
                              {product_staff_name ? <p className="mb-0 text-dark">{product_staff_name}</p> : ""}
                              <h6 className="mb-1">{dateOfInvoiced}</h6>
                              <span>Quantity: 1</span>
                            </div>
                            <label className="col-2 text-end">${productCost ? productCost : "0"}</label>
                          </div>
                        </li>
                      </>
                    ) : ""}
                    {CartData[item] && CartData[item].type && CartData[item].type === "Subscription" ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <label htmlFor="">{SubscriptionName ? SubscriptionName : ""}</label>
                            </div>
                            <label className="col-2 text-end">${SubscriptionCosts ? SubscriptionCosts : 0}</label>
                            <div className="col-10">
                              <label htmlFor="" className="fw-normal">With {client_subscription_staff ? client_subscription_staff : ""}</label>
                            </div>
                            {custom_first_amount ? (
                              <>
                                <div className="col-10">
                                  <label htmlFor="">Custom First Amount</label>
                                </div>
                                <label className="col-2 text-end">${custom_first_amount ? custom_first_amount : 0}</label>
                              </>
                            ) : ""}
                            {commencement_date ? (
                              <>
                                <div className="col-8">
                                  <label htmlFor="">CommenceMent Date</label>
                                </div>
                                <label className="col-4 text-end">{commencement_date ? commencement_date : ""}</label>
                              </>
                            ) : ""}
                            <span>Quantity: 1</span>
                          </div>
                        </li>
                        {client_subscription_signature ? (
                          <li className="border-top my-3 pt-3">
                            <label className="fw-semibold">Client Signature</label>
                            <div>
                            <img src={client_subscription_signature} alt="client_signature" style={{ width:"150px",height:"auto" }}/>
                            </div>
                          </li>
                        ) : ""}
                      </>
                    ) : ""}
                    {AppointMentObjData && ServiceName ? (
                      <>
                        {CartData[item] && CartData[item].type && CartData[item].type === "Service" ? null : (
                          <li className="border-top my-3 pt-3">
                            <div className="row gx-1 justify-content-between">
                              <div className="col-10">
                                <label htmlFor="">{ServiceName ? ServiceName : ""}</label>
                                <h6 className="mb-1">With {staffFullName} from {serviceStart + " - " + serviceEnds}</h6>
                                <span>Quantity: 1</span>
                              </div>
                              <label className="col-2 text-end">${ServiceCosts ? ServiceCosts : "0"}</label>
                            </div>
                          </li>
                        )}
                      </>
                    ) : ""}
                    {AddOnServiceData && AddOnServiceData.length > 0 ? Object.keys(AddOnServiceData).map((item, i) => {
                      let StaffAddonData = AddOnServiceData[item] && AddOnServiceData[item].staff;
                      let StaffFirstsName = AddOnServiceData[item] && StaffAddonData && StaffAddonData.first_name;
                      let StaffLastsName = AddOnServiceData[item] && StaffAddonData && StaffAddonData.last_name;
                      let staffFullNameS = AddOnServiceData[item] && StaffAddonData ? StaffFirstsName + " " + StaffLastsName : "";
                      let serviceNames = AddOnServiceData[item] && AddOnServiceData[item].service && AddOnServiceData[item].service.name;
                      let serviceStartingTimes = AddOnServiceData[item] && AddOnServiceData[item].start_time;
                      let serviceEndingsTimes = AddOnServiceData[item] && AddOnServiceData[item].end_time;
                      let serviceStarts = serviceStartingTimes ? moment(serviceStartingTimes, "HH:mm:ss").format("hh:mm A") : "";
                      let serviceEndings = serviceEndingsTimes ? moment(serviceEndingsTimes, "HH:mm:ss").format("hh:mm A") : "";
                      let ServicesCosts = AddOnServiceData[item] && AddOnServiceData[item].cost;
                      return (
                        <React.Fragment key={i}>
                          <li className="border-top my-3 pt-3">
                            <div className="row gx-1 justify-content-between">
                              <div className="col-10">
                                <label htmlFor="">{serviceNames ? serviceNames : ""}</label>
                                <h6 className="mb-1">With {staffFullNameS} from {serviceStarts + " - " + serviceEndings}</h6>
                                <span>Quantity: 1</span>
                              </div>
                              <label className="col-2 text-end">${ServicesCosts ? ServicesCosts : "0"}</label>
                            </div>
                          </li>
                        </React.Fragment>
                      )
                    }) : null}

                  </React.Fragment>
                )
              })
            ) : null}

            {currentUser && currentUser.salon && currentUser.salon.tax && currentUser.salon.tax.name === "Non-GST" ? "" : (
              <li className="border-top my-3 pt-3">
                <div className="row gx-1 justify-content-between">
                  <div className="col-10">
                    <h6 className="mb-0">Includes GST of</h6>
                  </div>
                  {/* <h6 className="mb-0 col-2 text-end">${memberShipTax ? parseFloat(memberShipTax).toFixed(2) : isTaxAmountClientPaidInvoice ? isTaxAmountClientPaidInvoice : "0"}</h6> */}
                  <h6 className="mb-0 col-2 text-end">${CalculatedTaxAmounts ? parseFloat(CalculatedTaxAmounts).toFixed(2) : "0"}</h6>
                </div>
              </li>
            )}
            <li className="border-top my-3 pt-3">
              <div className="row gx-1 justify-content-between">
                <label className="mb-0 col-10">Total AUD</label>
                {/* <label className="mb-0 col-2 text-end">${totalServiceCosts ? totalServiceCosts : isClientMemberShipTotalPay ? isClientMemberShipTotalPay : "0"}</label> */}
                <label className="mb-0 col-2 text-end">
                  ${TotalAudCosts ? parseFloat(TotalAudCosts).toFixed(2) : "0.00"}
                </label>
              </div>
            </li>
            {isSaleCompletedData && isSaleCompletedData.membership_service_usable_credit && parseFloat(isSaleCompletedData.membership_service_usable_credit) > 0 ? (
              <li className="border-top my-3 pt-3">
                <div className="row gx-1 justify-content-between">
                  <div className="col-10">
                    <h6 className="mb-0">Package Credit Used</h6>
                  </div>
                  <h6 className="mb-0 col-2 text-end">${isSaleCompletedData.membership_service_usable_credit ? isSaleCompletedData.membership_service_usable_credit : 0}</h6>
                </div>
              </li>
            ) : null}
            {isSaleCompletedData && isSaleCompletedData.voucher_discount && parseFloat(isSaleCompletedData.voucher_discount) > 0 ? (
              <li className="border-top my-3 pt-3">
                <div className="row gx-1 justify-content-between">
                  <div className="col-10">
                    <label className="mb-0 fw-normal col-10">
                      {appliedvoucherto.voucher ? appliedvoucherto.voucher.name : "One-Off Vocuher"} <span className="ms-2 mb-0 btn btn-outline-success p-1">{appliedvoucherto.code} </span>
                    </label>
                    {appliedvoucherto && appliedvoucherto.service && appliedvoucherto.service.name ? (
                      <label className="mb-0 fw-normal col-10">
                        Service Included : {appliedvoucherto.service.name}
                      </label>
                    ) : ""}
                  </div>
                  <h6 className="mb-0 col-2 text-end">${isSaleCompletedData.voucher_discount ? isSaleCompletedData.voucher_discount : 0}</h6>
                </div>
              </li>
            ) : null}
            {singlePaymentSale && singlePaymentSale.length > 0 && (saleStatus !== 'Schedule') ? (
              <>
                {singlePaymentSale.map((item, i) => {
                  const paidByStatusSale = item && item.paidby ? item.paidby : "";
                  const paidDateSale = item && item.created_at ? moment(item.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("DD/MM/YYYY") : "";
                  return (
                    <React.Fragment key={i}>
                      <li className="border-top my-3 pt-3">
                        <div className="row gx-1 justify-content-between">
                          <div className="col-10">
                            <h6 className="mb-0">{`Sale Paid By ${paidByStatusSale} ${paidDateSale}`}</h6>
                          </div>
                          <h6 className="mb-0 col-2 text-end">${singlepaymentCost}</h6>
                        </div>
                      </li>
                    </React.Fragment>
                  )
                })}
              </>
            ) : ""}
            {singlePaymentSaleRefund && singlePaymentSaleRefund.length > 0 ? (
              <>
                {singlePaymentSaleRefund.map((item, i) => {
                  const paidByStatusSale = item && item.paidby ? item.paidby : "";
                  const paidDateSale = item && item.created_at ? moment(item.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("DD/MM/YYYY") : "";
                  return (
                    <React.Fragment key={i}>
                      <li className="border-top my-3 pt-3">
                        <div className="row gx-1 justify-content-between">
                          <div className="col-10">
                            <h6 className="mb-0">{`Sale refund By ${paidByStatusSale} ${paidDateSale}`}</h6>
                          </div>
                          <h6 className="mb-0 col-2 text-end">${singlepaymentCostRefund}</h6>
                        </div>
                      </li>
                    </React.Fragment>
                  )
                })}
              </>
            ) : ""}
            {isSaleCompletedData && isSaleCompletedData.payment && isSaleCompletedData.payment.length > 0 ? (
              isSaleCompletedData.payment.map((paymentItem, i) => {
                const paidByStatus = paymentItem && paymentItem.paidby ? paymentItem.paidby : "";
                const amount = paymentItem && paymentItem.amount ? paymentItem.amount : "";
                const paidDate = paymentItem && paymentItem.created_at ? moment(paymentItem.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("DD/MM/YYYY") : "";

                return (
                  <React.Fragment key={i}>
                    {paymentItem && paymentItem.type === "Deposit" ? (
                      <>
                        <li className="border-top my-3 pt-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <h6 className="mb-0">{`Deposit Paid by ${paidByStatus} ${paidDate}`}</h6>
                            </div>
                            <h6 className="mb-0 col-2 text-end">${amount}</h6>
                          </div>
                        </li>
                      </>
                    ) : ""}
                  </React.Fragment>
                )
              })
            ) : ""}
            <li className="border-top my-3 pt-3">
              <div className="row gx-1 justify-content-between">
                <label className="mb-0 fw-normal col-10">Balance</label>
                {/* below condition checks NAN and negative than zero */}
                <label className="mb-0 fw-normal col-2 text-end">${isNaN((TotalAudCosts || 0) - (salePaid || 0) - (depositPaid || 0) - (isSaleCompletedData.voucher_discount || 0)) ? 0 : Math.max(0, (TotalAudCosts || 0) - (salePaid || 0) - (depositPaid || 0) - (isSaleCompletedData.voucher_discount || 0))}</label>
                {/* <label className="mb-0 fw-normal col-2 text-end">${(TotalAudCosts || 0) - (salePaid || 0) - (depositPaid || 0)}</label> */}
              </div>
            </li>
            {depositRefund && depositRefund > 0 ? (
              <>
                <li className="border-top my-3 pt-3">
                  <div className="row gx-1 justify-content-between">
                    <label className="mb-0 fw-normal col-10">Refund issued</label>
                    <label className="mb-0 fw-normal col-2 text-end">${depositRefund ? depositRefund : 0}</label>
                  </div>
                </li>
              </>
            ) : ""}
            {depositBalance && depositBalance > 0 && (saleStatus !== 'Schedule') ? (
              <li className="border-top my-3 pt-3">
                <div className="row gx-1 justify-content-between">
                  <button
                    type="button"
                    className="btn btn-apricots cursor-pointer"
                    onClick={() => {
                      if (depositBalance && depositBalance > 0) {
                        dispatch(RefundStripeToken(""));
                        dispatch(ActiveBtnState(""));
                        dispatch(CompletedSaleDataForRefund({ isSaleCompletedData: isSaleCompletedData, appointmentsArray: isSaleCompletedData && isSaleCompletedData.appointments && isSaleCompletedData.appointments.length > 0 ? isSaleCompletedData.appointments[0] : [], isdepositBalance: depositBalance ? depositBalance : 0, FullCartData: isSaleCompletedData && isSaleCompletedData.cart && isSaleCompletedData.cart.length > 0 ? isSaleCompletedData.cart : [] }));
                        dispatch(OpenStripeRefundCompletedSaleModal("open"));
                      } else {
                        setOpenModal(true)
                      }
                    }}
                  >
                    {t("Refund")}
                  </button>
                </div>
              </li>
            ) : ""}
          </ul>
        </div>
      </div>
    </div >
  )
};

export default ClientPaidInvoice;