import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from "yup";
import { Formik, FieldArray, Field } from "formik";
import yupconfig from "../../../../yupconfig";
import moment from 'moment';
import config from "../../../../config";
import { InputField } from '../../../../component/form/Field';
import { TaxValueApi } from 'store/slices/taxSlice';
import { creditcardDetailApi } from 'store/slices/creditcardUpdateSlice';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { ActiveBtnState, ClientInvoiceListViewApi, OpenClientInvoiceRefundModal, OpenClientInvoiceStripePayModalClient, OpenPartialPaidInvoice, OpenStripeRefundModal, RefundStripeToken } from 'store/slices/clientinvoiceSlice';
import { CalendarAddFormSetActivebtnState, ClientAppointmentListViewApi, OpenAppointmentcancelRefundStripe, appointmentDetailApi, appointmentListViewApi, appointmentUpdateApi, refundPaymentConfirmApi, refundPaymentFailedApi, refundPaymentStoreApi } from 'store/slices/appointmentSlice';
import { servicePriceApi } from 'store/slices/serviceSlice';
import { swalErrorHtml } from 'component/Sweatalert2';
import customToast from 'component/Toastr';
import { ActiveBtnStateClientAppointments, OpenClientRefundInvoiceStripe, OpenClientRefundSelection, OpenRefundInvoice, StripeTokenClientAppointments, openPastInvoiceDrawer } from 'store/slices/clientappointmentinvoiceSlice';
import { useNavigate } from 'react-router';

const RefundFromInvoiceModalAppointments = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [OpenConfirmModal, setOpenConfirmModal] = useState(false);
    const auth = useSelector((state) => state.auth);
    const currentUser = auth.user;
    const isFilter = useSelector((state) => state.appointment.isClientAppointmentFilterStatus);
    const isOpenClientRefundSelection = useSelector((state) => state.clientpastinvoice.isOpenClientRefundSelection);
    const isRefundInvoiceData = useSelector((state) => state.clientpastinvoice.isRefundInvoiceData);
    const isGetTotalDepositAmount = useSelector((state) => state.clientpastinvoice.isGetTotalDepositAmount);
    const isSaleCompletedData = useSelector((state) => state.clientinvoice.isSaleCompletedData);
    const appointmentDetail = useSelector((state) => state.appointment.isDetailData);
    const isGetCancelledAppointmentData = useSelector((state) => state.appointment.isGetCancelledAppointmentData);
    const isActiveBtnState = useSelector((state) => state.clientpastinvoice.isActiveBtnStateClientAppointments);
    const isStripeTokenClientAppointments = useSelector((state) => state.clientpastinvoice.isStripeTokenClientAppointments);
    const isGetAppointmentcancelRefundStripe = useSelector((state) => state.appointment.isGetAppointmentcancelRefundStripe);
    const servicesname = isRefundInvoiceData && isRefundInvoiceData.service && isRefundInvoiceData.service.name ? isRefundInvoiceData.service.name : "";
    const staffName = isRefundInvoiceData && isRefundInvoiceData.staff && (isRefundInvoiceData.staff.first_name && isRefundInvoiceData.staff.last_name) ? isRefundInvoiceData.staff.first_name + " " + isRefundInvoiceData.staff.last_name : "";
    const appoitmentTime = isRefundInvoiceData && isRefundInvoiceData.start_time && isRefundInvoiceData.end_time ? moment(isRefundInvoiceData.start_time, "HH:mm:ss").format("hh:mm A") + " - " + moment(isRefundInvoiceData.end_time, "HH:mm:ss").format("hh:mm A") : "";
    const cost = isRefundInvoiceData && isRefundInvoiceData.cost ? isRefundInvoiceData.cost : "";
    const clientName = isRefundInvoiceData && isRefundInvoiceData.client && (isRefundInvoiceData.client.first_name && isRefundInvoiceData.client.last_name) ? isRefundInvoiceData.client.first_name + " " + isRefundInvoiceData.client.last_name : "";
    const invoicedate = isRefundInvoiceData && isRefundInvoiceData.sale && isRefundInvoiceData.sale.invoicedate ? moment(isRefundInvoiceData.sale.invoicedate).format("DD/MM/YYYY") : "";
    const InvoiceTax = useSelector((state) => state.tax.isTaxValue);
    let taxPercentage = InvoiceTax && InvoiceTax.percentage;
    let tax = taxPercentage ? cost / taxPercentage : "";

    useEffect(() => {
        dispatch(TaxValueApi());
    }, []);


    const initialValues = {
        id: "",
        sale_id: "",
        client_id: "",
        total_deposit_refund: "",
        totaldeposit: "",
        deposit_payment_by: "",
        stripe_payment_method_id: "",
        stripe_token_id: "",
        total_cost: "",
        service_id: "",
        status: ""
    };

    const validationSchema = Yup.object().shape({
        total_deposit_refund: Yup.number()
            .required(t("Total deposit is required"))
            .typeError(t("Total deposit must be a number"))
            .min(0.0, t("Total deposit must be at least 0"))
            .max(isGetTotalDepositAmount && parseFloat(isGetTotalDepositAmount), t("Total deposit cannot exceed the amount paid"))
            .test('is-not-negative', t("Negative values are not allowed"), value => value >= 0.0)
    });
    yupconfig();

    const handleAppointmentRefund = async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            dispatch(refundPaymentStoreApi({ ...values })).then((action) => {
                if (action.meta.requestStatus === "fulfilled") {
                    const bookingstoredata = action.payload;
                    const clientSecret = bookingstoredata.clientSecret;
                    // const clientname = bookingstoredata.name;
                    // const clientemail = bookingstoredata.email;
                    // const clientphone = bookingstoredata.phone;
                    const stripe_payment_method_id = bookingstoredata.stripe_payment_method_id;
                    if (clientSecret) {
                        stripe
                            .confirmCardPayment(
                                clientSecret,
                                {
                                    payment_method: stripe_payment_method_id,
                                    // return_url: "http://localhost:3000",
                                },
                                // { handleActions: false },
                            )
                            .then(function (result) {
                                // Handle result.error or result.paymentIntent
                                if (result.error) {
                                    setError(`Payment failed ${result.error.message}`);
                                    setLoading(false);
                                    setStatus({ error: true, message: `Payment failed ${result.error.message}` });
                                    const valuespf = { ...bookingstoredata, error: result.error };
                                    dispatch(refundPaymentFailedApi(valuespf));
                                } else {
                                    const paymentIntent = result.paymentIntent;
                                    if (paymentIntent && paymentIntent.id && paymentIntent.status === "succeeded") {
                                        const valuesp = { ...bookingstoredata, paymentIntent: { id: paymentIntent.id, status: paymentIntent.status } };
                                        setError(null);
                                        dispatch(refundPaymentConfirmApi({ ...valuesp, ...values })).then((actionpayment) => {
                                            if (action.meta.requestStatus === "fulfilled") {
                                                if (actionpayment.meta.requestStatus === "fulfilled") {
                                                    setStatus({ success: true });
                                                    dispatch(openPastInvoiceDrawer(""))
                                                    dispatch(ClientAppointmentListViewApi({client_id: values.client_id,page_name:"client_appointment",isFilter: isFilter }));
                                                    setLoading(false);
                                                    dispatch(OpenRefundInvoice(""));
                                                    dispatch(OpenClientRefundSelection(""));
                                                    customToast.success(`${t("Refund Complete")}`);
                                                } else if (actionpayment.meta.requestStatus === "rejected") {
                                                    const status = actionpayment.payload && actionpayment.payload.status;
                                                    const errors = actionpayment.payload && actionpayment.payload.message && actionpayment.payload.message.errors;
                                                    const response = action.payload && action.payload.message && action.payload.message;
                                                    if (status === 422) {
                                                        const NotifyContent = () => {
                                                            return (
                                                                <>
                                                                    <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                                                                    <ul className="list-unstyled">
                                                                        {errors &&
                                                                            Object.keys(errors).map((a, n) => {
                                                                                if (errors[a].length > 0) {
                                                                                    return (
                                                                                        <>
                                                                                            {errors[a].map((as, ns) => {
                                                                                                return (
                                                                                                    <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                                                                                        {as}
                                                                                                    </li>
                                                                                                );
                                                                                            })}
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                </>
                                                            );
                                                        };
                                                        swalErrorHtml({ title: "Warning", html: <NotifyContent />, icon: "error" });
                                                        setErrors(errors);
                                                    }
                                                    setLoading(false);
                                                    setStatus({ success: false });
                                                    setSubmitting(false);
                                                }
                                            } else {
                                                setLoading(false);
                                            }
                                        })

                                    }
                                }
                            });
                    } else {
                        setStatus({ success: true });
                        dispatch(ClientAppointmentListViewApi({client_id: values.client_id,page_name:"client_appointment",isFilter: isFilter }));
                        dispatch(openPastInvoiceDrawer(""))
                        setLoading(false);
                        dispatch(OpenRefundInvoice(""));
                        dispatch(OpenClientRefundSelection(""));
                        customToast.success(`${t("Refund Complete")}`);
                    }
                } else if (action.meta.requestStatus === "rejected") {
                    const status = action.payload && action.payload.status;
                    const errors = action.payload && action.payload.message && action.payload.message.errors;
                    const response = action.payload && action.payload.message && action.payload.message;
                    if (status === 422) {
                        const NotifyContent = () => {
                            return (
                                <>
                                    <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                                    <ul className="list-unstyled">
                                        {errors &&
                                            Object.keys(errors).map((a, n) => {
                                                if (errors[a].length > 0) {
                                                    return (
                                                        <>
                                                            {errors[a].map((as, ns) => {
                                                                return (
                                                                    <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                                                        {as}
                                                                    </li>
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                }
                                            })}
                                    </ul>
                                </>
                            );
                        };
                        swalErrorHtml({ title: "Warning", html: <NotifyContent />, icon: "error" });
                        setErrors(errors);
                        setLoading(false);
                    } else if (status === 410) {
                        const NotifyContent = () => {
                            return (
                                <>
                                    <p className="mb-2 text-white text-justify">{response && response.message}</p>
                                </>
                            );
                        };
                        customToast.error(<NotifyContent />);
                        setStatus({ warning: response && response.message, booked: response && response.booked });
                        setLoading(false);
                    }
                    setLoading(false);
                    setStatus({ success: false });
                    setSubmitting(false);
                }
            });
        } catch (err) {
            setErrors(err.message);
            setStatus({ success: false });
            setLoading(false);
        }
    }
   
 
    return (
        <div>
            <div className={isOpenClientRefundSelection === "open" ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: isOpenClientRefundSelection === "open" ? "block" : "none" }}>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAppointmentRefund}>
                    {(formik) => {

                        useEffect(() => {
                            if (isStripeTokenClientAppointments) {
                                if (isStripeTokenClientAppointments.stripe_payment_method_id) {
                                    formik.setFieldValue("stripe_payment_method_id", isStripeTokenClientAppointments.stripe_payment_method_id)
                                    formik.setFieldValue("deposit_payment_by", "StripeCreditCard")
                                } else if (isStripeTokenClientAppointments.stripe_token_id) {
                                    formik.setFieldValue("stripe_token_id", isStripeTokenClientAppointments.stripe_token_id)
                                    formik.setFieldValue("deposit_payment_by", "StripeCreditCard")
                                }
                            }
                        }, [isStripeTokenClientAppointments])

                        useEffect(() => {
                            if (isRefundInvoiceData) {
                                formik.setFieldValue("id", isRefundInvoiceData && isRefundInvoiceData.id ? isRefundInvoiceData.id : "");
                                formik.setFieldValue("client_id", isRefundInvoiceData && isRefundInvoiceData.client_id ? isRefundInvoiceData.client_id : "");
                                formik.setFieldValue("sale_id", isRefundInvoiceData && isRefundInvoiceData.sale && isRefundInvoiceData.sale.id ? isRefundInvoiceData.sale.id : "");
                                formik.setFieldValue("service_id", isRefundInvoiceData && isRefundInvoiceData.service && isRefundInvoiceData.service.id ? isRefundInvoiceData.service.id : "");
                                formik.setFieldValue("total_cost", isRefundInvoiceData && isRefundInvoiceData.cost ? isRefundInvoiceData.cost : "");
                            }
                        }, [isRefundInvoiceData])

                        useEffect(() => {
                            if (isGetTotalDepositAmount) {
                                formik.setFieldValue("total_deposit_refund", isGetTotalDepositAmount ? isGetTotalDepositAmount : "");
                                formik.setFieldValue("totaldeposit", isGetTotalDepositAmount ? isGetTotalDepositAmount : "");
                            }
                        }, [isGetTotalDepositAmount])

                        return (
                            <div className="modal-dialog modal-lg modal-dialog-centered ac-setup">
                                <div className="modal-content ">
                                    <div className="modal-body pb-0">
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            <div className="p-3">
                                                <h5 className="mb-3 fw-semibold text-dark">{t("Issue Refund")}</h5>
                                                <div className="input-field">
                                                    <div className="row gx-2">
                                                        <div className="col-md-3">
                                                            <h6 className="fw-semibold mb-1">Item</h6>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6 className="fw-semibold mb-1">Quantity</h6>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6 className="fw-semibold mb-1">Amount Paid</h6>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6 className="fw-semibold mb-1">Refund</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-field">
                                                    <div className="row gx-2">
                                                        <div className="col-md-3">
                                                            <h6 className="mb-1">{`${servicesname}`}</h6>
                                                            <p className="mb-0">{`With ${staffName} on ${invoicedate} from ${appoitmentTime}`}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6 className="mb-1">1</h6>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6 className="mb-1">${formik.values.totaldeposit ? formik.values.totaldeposit : ""}</h6>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <InputField type="text" name="total_deposit_refund" value={formik.values.total_deposit_refund} controlId="AppointmentRefundInvoice-deposit" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {isRefundInvoiceData && isRefundInvoiceData.addonservices && isRefundInvoiceData.addonservices.length > 0 ? (
                                                    <div className="input-field">
                                                        <div className="row gx-2">
                                                            <label htmlFor="">Add On Services</label>
                                                            {isRefundInvoiceData.addonservices.map((item, i) => {
                                                                const addonServiceName = item && item.service && item.service.name;
                                                                let addonstaffFullName = item && item.staff && (item.staff.first_name && item.staff.last_name) ? item.staff.first_name + " " + item.staff.last_name : "";
                                                                let addonserviceStartTimes = item && item.start_time ? item.start_time : "";
                                                                let addonserviceStart = moment(addonserviceStartTimes, "HH:mm:ss").format("hh:mm A");
                                                                let addonserviceEndsTimes = item && item.end_time ? item.end_time : "";
                                                                let addonserviceEnds = moment(addonserviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                                                                let addonappointmentTime = item && item.dateof ? moment(item.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                                                                let addonTotalPay = item && item.cost ? item.cost : "";

                                                                return (
                                                                    <div className='col-12' key={i}>
                                                                        <h6 className="mb-1">{`${addonServiceName ? addonServiceName : ""}`}</h6>
                                                                        <p className="mb-0">With {addonstaffFullName} on {addonappointmentTime} from {addonserviceStart + " - " + addonserviceEnds}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                ) : ""}
                                                {isRefundInvoiceData && isRefundInvoiceData.addanotherservices && isRefundInvoiceData.addanotherservices.length > 0 ? (
                                                    <div className="input-field">
                                                        <div className="row gx-2">
                                                            <label htmlFor="">Add another Services</label>
                                                            {isRefundInvoiceData.addanotherservices.map((item, i) => {
                                                                const addanotherServiceName = item && item.service && item.service.name;
                                                                let addanotherstaffFullName = item && item.staff && (item.staff.first_name && item.staff.last_name) ? item.staff.first_name + " " + item.staff.last_name : "";
                                                                let addanotherserviceStartTimes = item && item.start_time ? item.start_time : "";
                                                                let addanotherserviceStart = moment(addanotherserviceStartTimes, "HH:mm:ss").format("hh:mm A");
                                                                let addanotherserviceEndsTimes = item && item.end_time ? item.end_time : "";
                                                                let addanotherserviceEnds = moment(addanotherserviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                                                                let addanotherappointmentTime = item && item.dateof ? moment(item.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                                                                let addanotherTotalPay = item && item.cost ? item.cost : "";

                                                                return (
                                                                    <div className='col-12' key={i}>
                                                                        <h6 className="mb-1">{addanotherServiceName ? addanotherServiceName : ""}</h6>
                                                                        <p className="mb-0">With {addanotherstaffFullName} on {addanotherappointmentTime} from {addanotherserviceStart + " - " + addanotherserviceEnds}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                ) : ""}
                                                {/* {currentUser && currentUser.salon && currentUser.salon.tax && currentUser.salon.tax.name === "Non-GST" ? "" : (
                                                    <div className="">
                                                        <div className="row gx-2">
                                                            <div className="col-md-3">
                                                                <h6 className="mb-1"></h6>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <h6 className="mb-1"></h6>
                                                            </div>
                                                            <div className="col-md-3 border-bottom-grays">
                                                                <h6 className="mb-0">Includes GST of</h6>
                                                            </div>
                                                            <div className="col-md-3 border-bottom-grays">
                                                                <h6 className="mb-0">${tax ? parseFloat(tax).toFixed(2) : "0"}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )} */}
                                                <div className="">
                                                    <div className="row gx-2">
                                                        <div className="col-md-3">
                                                            <h6 className="mb-1"></h6>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6 className="mb-1"></h6>
                                                        </div>
                                                        <div className="col-md-3 border-bottom-grays">
                                                            <h5 className="fw-semibold mb-1">Total AUD</h5>
                                                        </div>
                                                        <div className="col-md-3 border-bottom-grays">
                                                            <h5 className='mb-0'>${formik.values.total_deposit_refund}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                {OpenConfirmModal && (
                                                    <>
                                                        <div className={OpenConfirmModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: OpenConfirmModal ? "block" : "none" }}>
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-body pb-0">
                                                                        <div className="p-3">
                                                                            <h5 className="mb-3 fw-semibold text-dark">{t("Confirm Payment")}</h5>
                                                                            <p className="fw-semibold mb-2">{t(`Are you sure you want to refund this payment ${isActiveBtnState && isActiveBtnState === "CreditCard" ? "via credit card" : isActiveBtnState && isActiveBtnState === "Cash" ? "via cash" : "via stripe"} ?`)}</p>
                                                                        </div>
                                                                        <div className="row mt-3 bg-cream p-4 justify-content-center">
                                                                            <div className="col-6">
                                                                                <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                                                                                    setOpenConfirmModal(false);
                                                                                }}>
                                                                                    {t("Cancel")}
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <button className="btn btn-primary w-100" onClick={() => {
                                                                                    if (isActiveBtnState === "Cash") {
                                                                                        formik.setFieldValue("deposit_payment_by", "Cash")
                                                                                        setOpenConfirmModal(false);
                                                                                    } else if (isActiveBtnState === "CreditCard") {
                                                                                        formik.setFieldValue("deposit_payment_by", "CreditCard")
                                                                                        setOpenConfirmModal(false);
                                                                                    }
                                                                                }} type={isActiveBtnState === "StripeCreditCard" ? "submit" : "button"}>
                                                                                    {t("Confirm")}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {OpenConfirmModal && <div className="modal-backdrop show"></div>}
                                                    </>
                                                )}
                                                {/* Refund Type Button */}
                                                <div className="mb-3">
                                                    <h6 className="fw-semibold mb-3">Choose Refund Payment Type </h6>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-sm-4 mb-md-0 mb-3">
                                                            <button type="button" className={`btn ${formik.values.deposit_payment_by === "StripeCreditCard" ? "btn-dark" : "btn-light-gray"} w-100`} onClick={() => {
                                                                if (currentUser && currentUser.salon && currentUser.salon.is_stripe_verify == 1) {
                                                                    dispatch(creditcardDetailApi());
                                                                    dispatch(OpenClientRefundInvoiceStripe("open"))
                                                                } else {
                                                                    customToast.successwithlink("Please Setup Stripe For Taking Online payments ", "Set Stripe Now.", () => navigate("/settings?tab=Integrations"));
                                                                }
                                                            }}>
                                                                {t("Stripe")}
                                                            </button>
                                                        </div>
                                                        <div className="col-6 col-sm-4">
                                                            <button type="button" className={`btn ${formik.values.deposit_payment_by === "CreditCard" ? "btn-dark" : "btn-light-gray"} w-100`} onClick={() => {
                                                                setOpenConfirmModal(true);
                                                                dispatch(RefundStripeToken(""));
                                                                formik.setFieldValue("stripe_token_id", "");
                                                                dispatch(ActiveBtnStateClientAppointments("CreditCard"));
                                                            }}>
                                                                {t("Credit Card")}
                                                            </button>
                                                        </div>
                                                        <div className="col-6 col-sm-4">
                                                            <button type="button" className={`btn ${formik.values.deposit_payment_by === "Cash" ? "btn-dark" : "btn-light-gray"} w-100`} onClick={() => {
                                                                setOpenConfirmModal(true);
                                                                dispatch(StripeTokenClientAppointments(""));
                                                                formik.setFieldValue("stripe_token_id", "");
                                                                dispatch(ActiveBtnStateClientAppointments("Cash"));
                                                            }}>
                                                                {t("Cash")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-3 bg-cream p-4 justify-content-center">
                                                <div className="col-6">
                                                    <button className="preview btn me-1 cursor-pointer btn-preview w-100" type='button' onClick={() => {
                                                        dispatch(OpenClientRefundSelection(""));
                                                    }}>
                                                        {t("Cancel")}
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-primary w-100" disabled={loading || !(formik.values.deposit_payment_by === "Cash" || formik.values.deposit_payment_by === "CreditCard" || formik.values.deposit_payment_by === "StripeCreditCard")}>
                                                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                                                        {t("Refund Payments")}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            </div>
            {isOpenClientRefundSelection === "open" && <div className="modal-backdrop show"></div>}
        </div>
    )
}

export default RefundFromInvoiceModalAppointments;
